/* 
OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO 

he descubierto

https://www.data-driven-forms.org/
- buena documentación

https://form.io/
https://www.npmjs.com/package/@formio/react
https://medium.com/@edwinanciani/how-to-render-formio-forms-in-react-apps-1802161f1111 
- parece de pago

Pero NO parecen que se integren con ningún ORM, de forma que tb hagan el trabajo ORM

¿quizás podrían haber servido para realizar la parte de front-end y luego implementar la parte de back-end con prisma?
Una forma, relativamente fácil de hacerlo podría haber sido....
- con el ejemplo que hay en https://www.data-driven-forms.org/
- en la propiedad onSubmit, en lugar de hacer un console.log, hacer un fetch a un objeto que se encargue de hacer el insert en la base de datos o lo que se haya de hacer

Aunque solo con eso, haría falta lo necesario para pasarle las pre y post actions.

Por ahora continuco con el FormController creado.

*/


import React, { useState, useContext, useEffect }       from 'react';

import Box                                              from '@mui/material/Box';
import Button                                           from '@mui/material/Button';
import Dialog                                           from '@mui/material/Dialog';
import DialogTitle                                      from '@mui/material/DialogTitle';
import DialogContent                                    from '@mui/material/DialogContent';
import DialogActions                                    from '@mui/material/DialogActions';
import Typography                                       from '@mui/material/Typography';
//import Snackbar                                         from '@mui/material/Snackbar';
import { useTheme }                                     from '@mui/material/styles';
import { toast }                                        from 'sonner';

import { isDevelopmentDebug, isTrue }                        from '@/libs/general';
import { iFormController_schema,
         tDBAction,
         iFormController_Opened_Dialogs,
         iFormController_body,
       }                                                from '@/libs/interfaces';
import { FormController_Fields_Render, 
         FormController_Create_RequestBody, 
         FormController_Configuration,
         FormController_ALL_Fields_Validation,
         FormController_new_stt_FormSchema,
         FormController_new_stt_FormSchema_with_only_initialize_on_create,
         FormController_Field_Value,
         FormController_Identify_Exactly_DB_Action,
         }                                              from '@/components/FormController/FormController_functions';
import { Context_FormController }                       from '@/context/Context_FormController_Provider';
import { TryCatch_CLIENT_with_api }                     from '@/libs/db_prisma';    
import { console_log }                                  from '@/libs/debug';

import DebugBox                                         from '@/components/DebugBox';
import IconButton_customized                            from '@/components/customized_mui/IconButton_customized';
import Button_customized                                from '@/components/customized_mui/Button_customized';
import * as icons                                       from '@/styles/icons';

import { signIn }                                       from 'next-auth/react';
import { cloneDeep, isEqual }                           from 'lodash-es';
//import dynamic from 'next/dynamic'; // Añadir esto al inicio del archivo

interface iProps {
    p_stt_FormSchema: iFormController_schema,
    p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>,
    //estas 2 siguients vbles son para poder controlar el Dialog desde el componente de fuera que llama a este componente
    p_stt_Opened_Dialogs?: iFormController_Opened_Dialogs,
    p_sstt_Opened_Dialogs?: React.Dispatch<React.SetStateAction<iFormController_Opened_Dialogs>>,
    pOnFormSubmitSuccess?: (pResponse: any) => void; 
    pDebug?: boolean;
    }
const FormController: React.FC<iProps> = (props) => {


    /* useEffect(() => {
        console.log('Justo antes del render:', props);
        debugger; // Se activará después del primer render, útil para saber qué valores causaron cambios
        }); */
        
    const theme = useTheme();

    const showDebug = props.pDebug || false;

    //2024_05_06 *
    //hasta hoy este estado lo controlaba el componente que llama a este componente, pero voy a probar a hacerlo interno asi como los botones
    //que lo abren para que sea todo mas automatico, a ver si no salta por ningun lado.
    //igualmente he creado 
    //  - FormController_Dialog_Render
    //teniendo esto en cuenta

    const [stt_Opened_Dialogs, sstt_Opened_Dialogs] = useState<iFormController_Opened_Dialogs>({ Upsert_Opened: false,
        Create_Opened: false,
        Delete_Opened: false
        });

    //el upsert es p_stt_FormSchema que puede tener datos (update) o no (insert)
    const [stt_FormSchema_create, sstt_FormSchema_create] = useState<iFormController_schema>({})
    const [stt_FormSchema_delete, sstt_FormSchema_delete] = useState<iFormController_schema>({})

    const [stt_Login_OK, sstt_Login_OK] = useState<boolean>(true);


    //por defecto show debe estar a true, asi que si no se indica se pone a true
    //aqui da error, 
    //if (props.p_stt_FormSchema.show === undefined) {props.p_sstt_FormSchema({ ...props.p_stt_FormSchema, show: true });}
    //lo pongo en el useEffect siguiente (tal como me dice chatgpt) si solo pongo el primer parametro (props.p_stt_FormSchema) en los [] creo que da error
    useEffect(() => {
        //console.log ("useEffect FormController 1) para [props.p_stt_FormSchema, props.p_sstt_FormSchema]")
        if (props.p_stt_FormSchema.show === undefined) {
            props.p_sstt_FormSchema({ ...props.p_stt_FormSchema, show: true });
            }
        }, [props.p_stt_FormSchema, props.p_sstt_FormSchema]);
    



    //habia pensado en que por defecto se habilitaran los botones de Crear y Borrar, pero por seguridad los oculto por defecto y si se quieren mostrar debe indicarse en el esquema
    //if (!props.p_stt_FormSchema.createEnabled) {props.p_sstt_FormSchema({ ...props.p_stt_FormSchema, createEnabled: true });}
    //if (!props.p_stt_FormSchema.deleteEnabled) {props.p_sstt_FormSchema({ ...props.p_stt_FormSchema, deleteEnabled: true });}




    //2024_12_10
    //uso el siguiente useEffect para que cuando cambie el estado de props.p_stt_FormSchema se actualicen los estados de stt_FormSchema_create y stt_FormSchema_delete
    useEffect(() => {
        //console.log ("useEffect FormController 2) para [props.p_stt_FormSchema]")
        if (props.p_stt_FormSchema.createEnabled) {
            let cloned_FormSchema = FormController_new_stt_FormSchema_with_only_initialize_on_create(props.p_stt_FormSchema)
            cloned_FormSchema.DB_action = 'create';
            sstt_FormSchema_create(cloned_FormSchema);
            }
        if (props.p_stt_FormSchema.deleteEnabled) {
            let cloned_FormSchema = FormController_new_stt_FormSchema(props.p_stt_FormSchema);
            cloned_FormSchema.DB_action = 'delete';
            sstt_FormSchema_delete(cloned_FormSchema);
            }
        }, [props.p_stt_FormSchema]);



    //escucho posibles cambios externos
    useEffect(() => {
        //console.log ("useEffect FormController 3) para [props.p_stt_Opened_Dialogs]")
        //console.log ("props.p_stt_Opened_Dialogs", props.p_stt_Opened_Dialogs)
        // para sincrornizar con una posible vble externa que quiera abrir ese dialog cuando se modifica desde fuera
        if (props.p_stt_Opened_Dialogs) {
            sstt_Opened_Dialogs(props.p_stt_Opened_Dialogs);
            }
        //2024_12_10
        //cuando se abre un dialogo de creación, debo inicializar el stt_FormSchema_create
        //en teoria debiera está actualizado porque al cerrar con close_FormController_DialogS se vacia, pero me ha ocurrido por ejemplo 
        //al dar de alta habitaciones que no ocurria y no se porque, asi que lo vuelvo a hacer aqui para asegurarme que no se dan esos comportamientos "extraños".
        if (props.p_stt_Opened_Dialogs?.Create_Opened) {
            sstt_FormSchema_create(FormController_new_stt_FormSchema_with_only_initialize_on_create(props.p_stt_FormSchema));
            }
        }, [props.p_stt_Opened_Dialogs]);


    // pruebas, para ver si se establece bien stt_FormSchema_create
    /* useEffect(() => {
        //console.log ("useEffect FormController 4) para [stt_FormSchema_create]")
        //console.log ("stt_FormSchema_create.DB_data?.NUM_HABITACION", stt_FormSchema_create.DB_data?.NUM_HABITACION)
        }, [stt_FormSchema_create]); */





    //intento lo siguiente para poner el foco en el input email y asi que no aparezcan los mensajes en rojo al actualizar pero no funciona.
    /* useEffect(() => {
        const focusEmailInput = () => {
            const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement;
            if (emailInput) {emailInput.focus();}
            };
        requestAnimationFrame(focusEmailInput); // Usamos requestAnimationFrame para esperar a que la página termine el renderizado inicial
    
        // Alternativamente, podrías usar un pequeño retraso con setTimeout si `requestAnimationFrame` no es efectivo
        //setTimeout(focusEmailInput, 3350); //tampoco funciona
        }, []); */




    const close_FormController_DialogS   = (p_stt_FormSchema: iFormController_schema) => {
        //sstt_FormSchema_CreateDialog_isOpen(false);
        //sstt_FormSchema_DeleteDialog_isOpen(false);
        handle_Opened_Dialogs({ Upsert_Opened: false,
                                Create_Opened: false,
                                Delete_Opened: false
                               });

        //debo actualizar el p_stt_FormSchema para que se adecue al tipo de dialog que se va a mostrar
        // - si es create, debo ponerlo en blanco
        //   2024_09_30: OJO para aquellos campos que tengan initialize_on_create deben inicializarse
        // - en otro caso quizas valga con dejarlo tal cual 
        //
        const DB_action = FormController_Identify_Exactly_DB_Action(p_stt_FormSchema);
        if (['create', 'insert'].includes(DB_action!)) {
            //uso props.p_stt_FormSchema para que no sea una copia de una copia, de una copia,.... y partir de algo mas "estable"
            //aunque con p_stt_FormSchema tb debiera ir bien (pruebo)
            let cloned_FormSchema = FormController_new_stt_FormSchema_with_only_initialize_on_create(props.p_stt_FormSchema)
            cloned_FormSchema.DB_action = 'create';
            sstt_FormSchema_create(cloned_FormSchema);
            }


        };




/*     const handle_Opened_Dialogs = (pOpenedDialogs: iFormController_Opened_Dialogs) => {
        props.p_sstt_Opened_Dialogs?.(pOpenedDialogs); // para sincrornizar con una posible vble externa que quiera abrir ese dialog cuando se modifica desde dentro
        sstt_Opened_Dialogs(pOpenedDialogs); // Control interno 
        } */

    const handle_Opened_Dialogs = (pOpenedDialogs: iFormController_Opened_Dialogs) => {
        // Crear un objeto base con todas las propiedades a false
        const default_Opened_Dialogs: iFormController_Opened_Dialogs = {
            Upsert_Opened: false,
            Create_Opened: false,
            Delete_Opened: false
            };
        // Actualizar las propiedades indicadas en pOpenedDialogs
        const new_Opened_Dialogs: iFormController_Opened_Dialogs = {
            ...default_Opened_Dialogs,
            ...pOpenedDialogs
            };

        //2024_12_10
        //En teoria cuando se cierran los dialog se vacian de la forma adecuada los estados internos, stt_FormSchema_create y stt_FormSchema_delete
        //pero en la práctica, por ejemplo cuando creo una habitación e inmediatamente creo otra la segunda aparece con los datos de la primera
        //No consigo encontrar porque, asi que para evitar este comporntamiento vacio los estados internos al abrir el dialog
        //No va porque no pasa por aqui cuando se abre ya que se llama desde fuera y si pongo un ! para que se resetee cuando se cierra tampoco va.
        //Al final está dentro del useEffect que escucha cambios en props.p_stt_Opened_Dialogs

        //con lo anterior consigo que si no se indica una propiedad, se ponga a false
        props.p_sstt_Opened_Dialogs?.(new_Opened_Dialogs);  // Sincronizar con el posible estado externa
        sstt_Opened_Dialogs(new_Opened_Dialogs);            // Sincronizar con el estado interno
        };
    /* const open_FormController_DeleteDialog = (pOpened: boolean) => {
        props.p_sstt_Dialog_isOpen?.(pOpened); // para sincrornizar con una posible vble externa que quiera abrir ese dialog cuando se modifica desde dentro
        sstt_FormSchema_DeleteDialog_isOpen(pOpened);
        }     */

    const FormController_HandleSubmit_onclick = async (pEvent: React.MouseEvent<HTMLButtonElement> | null, 
                                                       p_stt_FormSchema: iFormController_schema,
                                                       p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>
                                                      ) => {
        //contrlo que pueda no venir un evento cuando, por ejemplo, llamo a esta función para crear un elemento nuevo
        //desde FormController_PossibleRecords_Insert
        if (pEvent) {
            pEvent.preventDefault();
            }
        

        //me dice si debo mostrar o no el toast ya que puede ocurrir que no deba mostrarse ya que estemos visualizando un listado de posibles registros a insertar y no es una acción de insertar "completada".
        function Show_Toast_if_not_possible_records_to_insert(pMessage: string, pResponseData: any): boolean {
            //console.log('pMessage:', pMessage);
            //console.log('pResponseData:', pResponseData);
            if (pResponseData.status == 200) {
                //si hay registros entre los que elegir para insertar, evito que se muestre el toast
                if (pResponseData.extended_response.response && 
                    Array.isArray(pResponseData.extended_response.response.possible_records_to_insert) &&
                    pResponseData.extended_response.response.possible_records_to_insert.length > 1) {
                    return false; // No Mostrar toast
                    }
                }
            // Decide si mostrar o no el toast basándose en la lógica de negocio
            return true; // No mostrar toast
            }

        let new_FormSchema = cloneDeep(p_stt_FormSchema);
        const DB_action = FormController_Identify_Exactly_DB_Action(p_stt_FormSchema);
        //si es login, lo gestiono a través de nextAuth
        if (DB_action === 'login') {
            //console.log ("dentro de DB_action === 'login'")
            const email     = FormController_Field_Value ("email", p_stt_FormSchema);
            const password  = FormController_Field_Value ("password", p_stt_FormSchema);

            //p_sstt_FormSchema({...p_stt_FormSchema, data_modified_by_user: false}); //al ser submitido ya no estan modificados por usuario, lo que hay en pantalla se corresponde con lo que hay en bbdd
            new_FormSchema.data_modified_by_user = false; //al ser submitido ya no estan modificados por usuario, lo que hay en pantalla se corresponde con lo que hay en bbdd

            const response = await signIn('credentials', {
                redirect: false,
                email,
                password
                });
          
            if (response?.ok) {
                sstt_Login_OK(true);
                if (props.pOnFormSubmitSuccess) {
                    props.pOnFormSubmitSuccess(response); // Llama al callback
                    }
                } 
            else {
                //setError(result?.error || 'Error de autenticación');
                sstt_Login_OK(false);
                console.log ("Error de autenticación")
                } 
            }
        //si hemos dado al boton de "añadir" pero ya existen registros posibles a insertar, los vaciamos ya que ahora ese botón tiene la función de ir "Atras"
        //y al vaciar se consigue mostrar la pantalla anterior -> el formulario
        else if (['create', 'insert'].includes(DB_action ?? '') && 
                 //p_stt_FormSchema.search_possible_records_to_insert &&         
                 p_stt_FormSchema.search_possible_records_to_insert?.possible_records &&
                 p_stt_FormSchema.search_possible_records_to_insert?.possible_records?.length > 0
                ) {
            new_FormSchema.search_possible_records_to_insert!.possible_records = [];
            }
        else {
            const objRequestBody = FormController_Create_RequestBody (p_stt_FormSchema) 
            //console_log(["formData:", formData], "form_controller API"); 
            //console_log(["formData:", formData], "DeleteUpdate Accommodation");
            const response = await TryCatch_CLIENT_with_api('/api/form_controller', objRequestBody, Show_Toast_if_not_possible_records_to_insert);  

            new_FormSchema.data_modified_by_user = false; //al ser submitido ya no estan modificados por usuario, lo que hay en pantalla se corresponde con lo que hay en bbdd

            if (response.status === 200) {
                let close_dialog = true;
                //close_FormController_DialogS
                const db_action = response.extended_response.request.DB_action
                //si estamos haciendo una insert y se han encontrado registros posibles a insertar, los muestro
                if (['create', 'insert'].includes(db_action) && response.extended_response?.response?.possible_records_to_insert) {
                    //alert("debiera mostrar un dialogo con los registros posibles a insertar")
                    if (new_FormSchema.search_possible_records_to_insert) {
                        new_FormSchema.search_possible_records_to_insert.possible_records = response.extended_response.response.possible_records_to_insert;
                        }
                    close_dialog = false;
                    }
                //si se ha borrado el registro lo vacio.
                if (['delete'].includes(db_action)) {
                    new_FormSchema = FormController_new_stt_FormSchema(new_FormSchema, {});
                    //¿ aunque quizas debiera usar FormController_new_stt_FormSchema_with_only_initialize_on_create ?
                    }

                if (close_dialog) {
                    close_FormController_DialogS(p_stt_FormSchema); //cierro ambos para no tener que andar controlando cual es el actual.
                    if (props.pOnFormSubmitSuccess) {
                        props.pOnFormSubmitSuccess(response); // Llama al callback
                        }
                    }
                //FormController_snackbar_Open(ssst_SnackBar_isOpen, "Tu mensaje de éxito aquí");
                }
            else {
                if (isDevelopmentDebug()) {alert("Error en el servidor, ver console.");}
                console.log ("Error en el servidor, ver justo encima")
                //console.log (response.error)
                }
            }

        p_sstt_FormSchema(new_FormSchema);

        };



    function FormController_Buttons_Render (p_stt_FormSchema: iFormController_schema, 
                                            p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>,
                                            pIsDialog?: boolean) {

        const objConf = FormController_Configuration(p_stt_FormSchema)
        //controlo que todos los fields tienen un valor valido, y de no ser así deshabilito el botón de submit
        const ALL_fields_validation = FormController_ALL_Fields_Validation(p_stt_FormSchema);
        //const is_disabled = !ALL_fields_validation || p_stt_FormSchema.submit?.disabled || false;
        const is_disabled = !ALL_fields_validation || false;
        const DB_action = FormController_Identify_Exactly_DB_Action(p_stt_FormSchema);

        let delete_enabled = false;
        if (!pIsDialog && p_stt_FormSchema.deleteEnabled ) {
            delete_enabled = p_stt_FormSchema.deleteEnabled;
            }

        let delete_button_show = !pIsDialog && DB_action != "login"; //por defecto no se muestra el boton de borrar cuando no es dialog
        if (p_stt_FormSchema.deleteShow) {
            delete_button_show = p_stt_FormSchema.deleteShow;
            }

        let delete_tooltip = objConf.delete_label;
        if (!delete_enabled && p_stt_FormSchema.deleteDisabled_message) {
            delete_tooltip = p_stt_FormSchema.deleteDisabled_message;
            }


        return (
            <>
            {/* <Box sx={{ }}>
                DB_action: {DB_action}
            </Box> */}

            {/* <DebugBox 
                pData={objConf} 
                pLabel={'objConf'}
                pShow={showDebug}
                pOnlyTooltip={true}
                //pExcludedProps={["children"]}
                pOpenMode={'click'}
                //pWidth={400} 
                /> */}

            {/* <DebugBox 
                pData={p_stt_FormSchema} 
                pLabel={'FormController_Buttons_Render > p_stt_FormSchema'}
                pShow={showDebug}
                pOnlyTooltip={true}
                pExcludedProps={["fields"]}
                pOpenMode={'click'}
                pWidth={500} 
                /> */}

            <Box sx={{ 
                    marginTop: p_stt_FormSchema.show ? '25px' : '0px',
                    textAlign: (DB_action == 'login')? 'center' : 'left',
                    //border: '1px solid #e0e0e0',
                    width: '100%', //para que se alineen bien los botones en el dialog
                    }}>
                {/* 
                OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO OJO 
                
                <Box>FC 0</Box>
                entre esto y el siguiente box FC 1
                aparece un boton con borde azul y fondo gris, que no se de donde sale 

                el problema parece estar en Button_customized pero no consigo controlarlo.

                */}

                {/* boton de SUBMIT */}     
                <Button_customized
                    //type="submit" 
                    //no pongo el tooltip aqui ya que incomoda el uso (ensayo-error)
                    //pTooltip={objConf.title_label}
                    pVariant={(objConf.submit_label == "Atrás") ? "default" : "solid"}
                    color={(objConf.submit_label == "Borrar") ? "error" : "primary"}
                    sx ={{ 
                        //boxShadow: 'none',
                        //borderStyle: 'solid',
                        //textTransform: 'uppercase',
                        textTransform: (objConf.submit_label == "Atrás") ? "none" : "uppercase",
                        //Cuando sea un dialogo lo pongo a la izquierda, pero ¿ porque tb cuando !p_stt_FormSchema.show ?
                        float: (pIsDialog || !p_stt_FormSchema.show) ? 'left' : 'none',
                        //float: 'center',
                        //backgroundColor: (objConf.submit_label == "Borrar") ? theme.palette.error.light : undefined,
                        //borderColor: (objConf.submit_label == "Borrar") ? theme.palette.error.light : undefined,
                        //display: 'none',
                        }}
                    disabled={is_disabled}
                    //NO se pone la funcion del submit aqui sino que está mas arriba en el Box component="form"
                    onClick={(e) => FormController_HandleSubmit_onclick(e, p_stt_FormSchema, p_sstt_FormSchema)}
                    >
                    {objConf.submit_label}
                </Button_customized>

                {/* <Box>FC 1</Box> */}
                {
                DB_action === 'login' && (
                    <Box
                    sx={{
                        //en caso de que el login no sea ok y que el formularion no ha sido modificado desde el ultimo submit, muestro el mensaje de error
                        visibility: (!stt_Login_OK && !p_stt_FormSchema.data_modified_by_user) ? 'visible' : 'hidden', //se reserva el espacio para el mensaje aunque no aparezca, para que no cambie la pos del form login cuando no hay mensaje
                        color: theme.palette.error.main,
                        textAlign: 'center',
                        mt: 3,
                        //transition: 'height 21.3s ease-in-out, visibility 21.3s ease-in-out', //parece no funcionar
                        }}
                        >
                        <Typography>El Email/Password es incorrecto</Typography>
                    </Box>
                    )
                }

                
                {
                // dibujo el botón de AÑADIR solo si estamos dibujando el formulario inline
                //!pIsDialog && p_stt_FormSchema.createEnabled && p_stt_FormSchema.prepostActions?.onCreate && (
                !pIsDialog && p_stt_FormSchema.createEnabled &&  (
                    <Button_customized
                        //pIconComponent={AddIcon}
                        onClick={() => handle_Opened_Dialogs({Create_Opened: true})}
                        sx = {{ml: 8}}
                        pTooltip={objConf.add_label}
                        pIcon={<icons.Add />}
                        >Añadir</Button_customized>
                    )}

                { delete_button_show && (
                    <Button_customized
                        //pIconComponent={AddIcon}
                        onClick={() => handle_Opened_Dialogs({Delete_Opened: true})}
                        sx = {{
                            //ml: (p_stt_FormSchema.createEnabled && p_stt_FormSchema.prepostActions?.onCreate) ? 1 : 8,
                            ml: (p_stt_FormSchema.createEnabled) ? 1 : 8,
                            color: theme.palette.error.light,
                            borderColor: theme.palette.error.light
                            }}
                        pTooltip={delete_tooltip}
                        pIcon={<icons.Delete />}
                        disabled={!delete_enabled}
                        >Borrar</Button_customized> 
                    )}

                
                {
                // dibujo el botón de CERRAR/CANCELAR solo si estamos dibujando el formulario en un dialog
                (!p_stt_FormSchema.show || pIsDialog) && (
                    <Button_customized
                        //variant="outlined"
                        sx = {{ ml: 2,    
                                float: 'right'
                                }}
                        onClick={(e) => {close_FormController_DialogS(p_stt_FormSchema)}}>Cancelar</Button_customized>
                    )}
            </Box>
            </> 
            );  
    }


    function FormController_Has_PossibleRecords(p_stt_FormSchema: iFormController_schema) {
        const possible_records_to_insert = p_stt_FormSchema.search_possible_records_to_insert?.possible_records;
        const has_possible_records_to_insert = Array.isArray(possible_records_to_insert) && possible_records_to_insert.length > 0;
        return has_possible_records_to_insert;
        }
    // Nueva función para renderizar posibles registros
    // en caso de haber posibles registros a insertar devuelve un componente sino null
    function FormController_PossibleRecords_Render(p_stt_FormSchema: iFormController_schema,
                                                   p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>
                                                    ) {
        const possible_records_to_insert = p_stt_FormSchema.search_possible_records_to_insert?.possible_records;
        const has_possible_records_to_insert = Array.isArray(possible_records_to_insert) && possible_records_to_insert.length > 0;
        //la siguiente vble está en mayúsculas porque puede ser un componente
        const Record_Component = p_stt_FormSchema.search_possible_records_to_insert?.buttons_component;
        let mensaje = '';
    
        if (has_possible_records_to_insert) {
            let records_to_display = [];
    
            if (possible_records_to_insert[0]?.NIVEL_COINCIDENCIA > 90) { // Si el primer registro tiene NIVEL_COINCIDENCIA > 90, solo mostrar ese botón
                records_to_display = possible_records_to_insert.slice(0, 1);
                mensaje = 'Se ha encontrado el registro, elígelo con el botón superior.';
                } 
            else { // si no, mostrar los 10 primeros registros + otro que permita crear un nuevo registro
                records_to_display = possible_records_to_insert.slice(0, 10);
                if (possible_records_to_insert.length > 10) {
                    mensaje = 'Se han encontrado más de 10 coincidencias, quizás deba indicar más datos';
                }
            }
    
            return (
                <Box sx={{}}>
                    <Typography sx={{ mb: 2 }}>
                        Elije el {p_stt_FormSchema.name} que asignar.
                    </Typography>
                    {Record_Component
                        ? records_to_display.map((record: any, index: number) => {
                              if (typeof Record_Component === 'string') {
                                  let record_string = Record_Component.replace(/\$\{(\w+)\}/g, (_, property) => record[property] ?? '');
                                  if (process.env.NODE_ENV !== 'development') {
                                      record_string = record_string.replace(/\{\{DEV: (.*?)\}\}/g, '');
                                  }
                                  return (
                                      <Button_customized
                                          key={index}
                                          fullWidth
                                          variant="outlined"
                                          sx={{
                                              mb: 2,
                                              borderStyle: 'dashed',
                                              textTransform: 'none',
                                          }}
                                          disabled={record.EXISTE === "-1" || isTrue(record.EXISTE)} //el -1 es porque en la consulta de ryc_1 Crear_Consulta_Existe_Jugador está asi.
                                          onClick={() => FormController_PossibleRecords_Insert(p_stt_FormSchema, p_sstt_FormSchema, record)}
                                          pTooltip={(record.EXISTE === "-1" || isTrue(record.EXISTE)) 
                                                        ? 'No es posible insertar este registro\n porque ya existe en este campeonato.'
                                                        : 'Haz clic para insertar este registro'}
                                      >
                                          {record_string}
                                      </Button_customized>
                                  );
                              } else {
                                  return <Record_Component key={index} {...record} index={index} />;
                              }
                          })
                        : null}

                    {(possible_records_to_insert[0]?.NIVEL_COINCIDENCIA <= 90) 
                        ? (
                            <Button_customized
                                key="nuevo"
                                fullWidth
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                    borderStyle: 'dashed',
                                    textTransform: 'none',
                                }}
                                onClick={() => FormController_PossibleRecords_Insert(p_stt_FormSchema, p_sstt_FormSchema,"NEW")}
                                pTooltip={'No deseo ninguna de las opciones anteriores,\n creame un nuevo registro'}
                                >
                                Nuevo
                            </Button_customized>
                           ) 
                        : null
                        }

                    {mensaje && (
                        <Typography variant="body2" 
                            sx={{ 
                                mt: 2, 
                                //backgroundColor: theme.palette.warning.light,
                                color: theme.palette.error.light,
                                textAlign: 'center',
                                }}
                            >
                            {mensaje}
                        </Typography>
                        )}
                </Box>
            );
        }
        return null;
        } 

    async function FormController_PossibleRecords_Insert(p_stt_FormSchema: iFormController_schema, 
                                                         p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>,
                                                         p_RECORD: any) {
        console.log ("dentro de FormController_PossibleRecords_Insert")

        //si es NEW (porque el usuario ha indicado que quiere un nuevo registro)...
        if (p_RECORD === "NEW") {
            //alert("creo un uuario nuevo.")
            let new_FormSchema = cloneDeep(p_stt_FormSchema);
            //para evitar que intente buscar y directamente inserte, cambio las siguientes propiedades.
            new_FormSchema.search_possible_records_to_insert!.possible_records = [];
            new_FormSchema.search_possible_records_to_insert!.function_search_name = '';
            new_FormSchema.search_possible_records_to_insert!.function_search_params = {};
            await FormController_HandleSubmit_onclick (null, new_FormSchema, p_sstt_FormSchema);
            } 
        else {
            const function_insert_name = p_stt_FormSchema.search_possible_records_to_insert?.function_insert_name
            const function_insert_params = p_stt_FormSchema.search_possible_records_to_insert?.function_insert_params
            //const COD_field_name = p_stt_FormSchema.search_possible_records_to_insert?.function_insert_COD_field_name
            //if (!function_insert_name || !function_insert_params || !COD_field_name) {
            //    console.log('No se ha definido la función de inserción, sus parametros o el nombre del campo de código para la inserción.');
            if (!function_insert_name || !function_insert_params) {
                console.log('No se ha definido la función de inserción o sus parametros.');
                return null;
                }
            else {
                  const FormController_body: iFormController_body = {
                    DB_table            : p_stt_FormSchema.DB_table, 
                    DB_action           : 'insert', //necesario para que por ejemplo on_Person_Updated vaya bien
                    ignore_CoreAction   : true, 
                    prepostActions      : {
                                            postAction: {name: function_insert_name,
                                                        params: {...p_RECORD, ...function_insert_params}
                                                        }
                                            }
                    }
    
                let response = await TryCatch_CLIENT_with_api('/api/form_controller', FormController_body, true);
                if (response.status === 200) {
                    //response = response.extended_response.response;
                    if (!response.controlled_error) {
                        close_FormController_DialogS(p_stt_FormSchema); //cierro ambos para no tener que andar controlando cual es el actual.
                        if (props.pOnFormSubmitSuccess) {
                            props.pOnFormSubmitSuccess(response); // Llama al callback
                            }
                        }
                    }
                }
            }

       
        return null;
        }



    //Recorre el FormSchema y dibuja el formulario
    function FormController_Render(p_stt_FormSchema: iFormController_schema,
                                   p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>
        ) {
        // Elementos DebugBox que siempre se renderizan
        const DebugBox_elements = (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        //alignItems: 'flex-start',
                        //justifyContent: 'flex-start',
                        //border: "1px solid red",
                        //mb:2,
                        gap:1,
                        }}
                    >
                    <DebugBox 
                        pData={p_stt_FormSchema} 
                        pLabel={'props.p_stt_FormSchema' + (p_stt_FormSchema.DB_table ? ' (' + p_stt_FormSchema.DB_table + ')' : '')}
                        //pLabelColor='silver'
                        pShow={showDebug}
                        pOnlyTooltip={true}
                        //pExcludedProps={["fields"]}
                        pOpenMode={'click'}
                        pWidth={500} 
                    />
                    <Box
                        sx = {{
                            //border:"1px dashed pink",
                            pl: 2,
                            mb: 2,
                            display: (showDebug) ? 'block' : 'none'
                            }}
                        >
                        <DebugBox 
                            pData={stt_FormSchema_create} // Asumo que es un objeto adecuado; ajusta según sea necesario
                            pLabel={'stt_FormSchema_create'}
                            //pLabelColor='silver'
                            pShow={showDebug}
                            pOnlyTooltip={true}
                            pOpenMode={'click'}
                            pWidth={450} 
                        />
                        <DebugBox 
                            pData={stt_FormSchema_delete} // Asumo que es un objeto adecuado; ajusta según sea necesario
                            pLabel={'stt_FormSchema_delete'}
                            //pLabelColor='silver'
                            pShow={showDebug}
                            pOnlyTooltip={true}
                            pOpenMode={'click'}
                            pWidth={450} 
                        />
                    </Box>
                    {/* <DebugBox 
                        pData={{prop: props.p_stt_Opened_Dialogs, state: stt_Opened_Dialogs}} // Asumo que es un objeto adecuado; ajusta según sea necesario
                        pLabel={'props.p_stt_Opened_Dialogs, stt_Opened_Dialogs'}
                        pShow={showDebug}
                        pOnlyTooltip={true}
                        pOpenMode={'click'}
                        pWidth={450} 
                    /> */}
                </Box>
            </>
            );


            
        // Componente de formulario que se muestra condicionalmente
        const pkField = p_stt_FormSchema.fields?.find(field => field.DB_is_pk);
        const DB_action = FormController_Identify_Exactly_DB_Action(p_stt_FormSchema);
        const Box_form = (
            (!pkField || !pkField.value) && DB_action !== 'login' 
                ? (
                    <Button_customized
                        pIcon={<icons.Add />}
                        color="primary"
                        sx={{ textTransform: 'none' }}
                        onClick={() => handle_Opened_Dialogs({ Create_Opened: true })}
                        >
                        {"Añadir " + p_stt_FormSchema.name}
                    </Button_customized>
                    ) 
                : (
                    <Box component="form">
                    {FormController_Fields_Render(p_stt_FormSchema, p_sstt_FormSchema)}
                    {FormController_Buttons_Render(p_stt_FormSchema, p_sstt_FormSchema)}
                    </Box>
                    )
            );

        // Renderiza siempre debugElements y condicionalmente el formulario
        return (
            <>
                {DebugBox_elements}
                {/* {FormController_PossibleRecords_Render(p_stt_FormSchema) || (p_stt_FormSchema.show && <Box_form />)} */}
                {
                (FormController_Has_PossibleRecords(p_stt_FormSchema)) 
                    ? FormController_PossibleRecords_Render(p_stt_FormSchema, p_sstt_FormSchema)
                    : (p_stt_FormSchema.show && Box_form) 
                    }
            </>
            );
        }

    //Recorre el FormSchema y dibuja el formulario 
    function FormController_Dialog_Render ( p_stt_FormSchema: iFormController_schema,
                                            p_sstt_FormSchema: React.Dispatch<React.SetStateAction<iFormController_schema>>,
                                            p_stt_Dialog_isOpen: boolean
                                            ) {
        //2024_12_10
        //He intentado forzar aqui la inicialización del estado stt_FormSchema_create pero no vale ya que pasa por aqui cada vez que 
        //se modifica un input del formulario y entra en conflicto
        //Al final está dentro del useEffect que escucha cambios en props.p_stt_Opened_Dialogs


        const objConf = FormController_Configuration(p_stt_FormSchema)
        const has_possible_records_to_insert = FormController_Has_PossibleRecords(p_stt_FormSchema)
        return ( //hace falta este return para que al llamar a este renderizado devuelva un JSX tal como espera el otro componente
            <>
            
            <Box component="form"  className = "FC_form_dialog"
                //noValidate 
                //autoComplete="off" 
                //onSubmit={(e) => FormController_HandleSubmit_v0(p_stt_FormSchema, e)}
                //sx = {{ minWidth: "700px", width: "700px" }} //no sirve para establecer el ancho del dialog
                >
                <Dialog 
                    open={p_stt_Dialog_isOpen} 
                    onClose={(e) => {close_FormController_DialogS(p_stt_FormSchema)}} //cierro ambos para no tener que andar diferenciando
                    //sx = {{ minWidth: "700px", width: "700px" }} //no sirve para establecer el ancho del dialog
                    > 
                    <DialogTitle>{objConf.title_label}</DialogTitle>
                    <DialogContent>
                        <DebugBox 
                            pData={p_stt_FormSchema} 
                            pLabel={'FormController_Dialog_Render > p_stt_FormSchema'}
                            pShow={true}
                            pOnlyTooltip={true}
                            //pExcludedProps={["children"]}
                            pOpenMode={'click'}
                            pWidth={550} 
                            />
                        {/* dibujo...
                            ...los posibles registros a elegir para una insercción 
                            ...o sino hay posibles registros, dibujo los campos del formulario    
                        */}
                        {has_possible_records_to_insert 
                            ? FormController_PossibleRecords_Render(p_stt_FormSchema, p_sstt_FormSchema) 
                            : FormController_Fields_Render(p_stt_FormSchema, p_sstt_FormSchema)
                            }
                    </DialogContent>
                    <DialogActions>
                        {/* dibujo los botones en caso de no exixtir posibles registros a insertar 
                        {!box_possible_records_to_insert && FormController_Buttons_Render(p_stt_FormSchema, p_sstt_FormSchema, true)}
                        */}
                        {FormController_Buttons_Render(p_stt_FormSchema, p_sstt_FormSchema, true)}
                    </DialogActions>
                </Dialog> 
            </Box>
            </>
            );  
        }

/*     function open_FormController_Dialog (pAction: tDBAction) {
        sstt_FormSchema_dialog_isOpen(true);
        }

    function close_FormController_Dialog () {
        sstt_FormSchema_dialog_isOpen(false);
        } */

    return (
        <>

        {
        FormController_Render(props.p_stt_FormSchema, props.p_sstt_FormSchema) 
        }
        {
        (stt_Opened_Dialogs.Upsert_Opened === true) && (
            FormController_Dialog_Render(props.p_stt_FormSchema, props.p_sstt_FormSchema, stt_Opened_Dialogs.Upsert_Opened)
            )}
        {
        (stt_Opened_Dialogs.Create_Opened === true) && (
            FormController_Dialog_Render(stt_FormSchema_create, sstt_FormSchema_create, stt_Opened_Dialogs.Create_Opened)
            )}

        {
        (stt_Opened_Dialogs.Delete_Opened === true) && (
            FormController_Dialog_Render(stt_FormSchema_delete, sstt_FormSchema_delete, stt_Opened_Dialogs.Delete_Opened)
            )}


        {/* 
        OBSOLETO
        lo quito ya que uso toast de sonner que es mas sencillo de configurar 
        y no me de el efecto secundario que ocurria con snackbar que hasta que no se cerraba no me permitia, por ejemplo, volver a abrir la ventana que lo habia generado
        <Snackbar
                //open={openSnackbar}
                open    ={ctx_FC_Snackbar.isOpen}
                message ={ctx_FC_Snackbar.message}
                //open={true}
                autoHideDuration={5000}
                //onClose={(event, reason) => FormController_snackbar_Close(ssst_SnackBar_isOpen, reason)}
                onClose={ctx_FC_Snackbar_Close}
                //message="Mensaje del Snackbarrrrrrrrrrrrr" // Puedes personalizar este mensaje
                /> */}

        </>
        );
};


export default FormController;
